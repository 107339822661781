import { track } from "@vercel/analytics";
import Banner from "../assets/pnp-xmas.png";
import { BASE_URL } from "../utils";
export default function HolidayBanner() {
  return (
    <div
      className="relative w-full overflow-hidden rounded-lg cursor-pointer"
      onClick={() => {
        track("xmas-collection");
        window
          .open(
            `${BASE_URL}collections/christmas-2024-gifts-for-pets?utm_source=Insta_website&utm_medium=instagram%2Cwebsite%2CFidoFashionStatement%2CShihTzu&utm_campaign=Insta_FidoFashionStatement&utm_co&utm_term=instawebFidoFashionStatement`,
            "_blank"
          )
          .focus();
      }}
    >
      <div className="aspect-[16/9] w-full">
        <img
          src={Banner}
          alt="Holiday banner background"
          layout="fill"
          objectFit="cover"
          className="rounded-lg"
        />
      </div>
      <div className="absolute inset-0 flex flex-col  items-start p-5 space-y-2">
        <h2 className="text-lg font-bold  max-w-[200px] mt-2">
          Merry, bright, and everything nice!
        </h2>
        <p className="text-sm max-w-[180px]">
          Shop Christmas goodies for your fur babies.
        </p>
      </div>
    </div>
  );
}
